<template>
  <div>
    <h1>
      अखबारों में
      <small>सम्पूर्ण भारत परिक्रमा देश के अखबारों</small>
    </h1>
    <LightBox :images="news" type="news" />
  </div>
</template>

<script>
import LightBox from "@/components/LightBox.vue";

export default {
  name: "InNews",
  components: { LightBox },
  data() {
    return {
      toggler: true,
      newsItems: [
        "21indore_2.jpg",
        "21indore_1.jpg",
        "20vishakha_10.jpg",
        "20vishakha_9.jpg",
        "20vishakha_8.jpg",
        "20vishakha_7.jpg",
        "20vishakha_6.jpg",
        "20vishakha_5.jpg",
        "20vishakha_4.jpg",
        "20vishakha_3.jpg",
        "20vishakha_2.jpg",
        "20vishakha_1.jpg",
        "19ongole_2.jpg",
        "19ongole_1.jpg",
        "18bhavnagar.jpg",
        "17jamnagar.jpg",
        "16jaisalmer_3.jpg",
        "16jaisalmer_2.jpg",
        "16jaisalmer_1.jpg",
        "15bikaner.jpg",
        "14raipur.jpg",
        "14dehradun.jpg",
        "13imphal.jpg",
        "13bhilai.jpg",
        "12betul.jpg",
        "11bhopal.jpg",
        "01indore_10.jpg",
        "01indore_9.jpg",
        "01indore_8.jpg",
        "01indore_7.jpg",
        "01indore_6.jpg",
        "01indore_5.jpg",
        "01indore_4.jpg",
        "01indore_3.jpg",
        "01indore_2.jpg",
        "01indore_1.jpg",
      ],
    };
  },
  computed: {
    news() {
      return [...this.newsItems].reverse();
    },
  },
};
</script>
